var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"clock-wrapper"},[_c('div',{staticClass:"box text-center"},[_vm._m(0),(this.$store.state.getClockData == null)?_c('vue-clock'):(
        this.$store.state.getClockData.timein != '' &&
        this.$store.state.getClockData.timeout == null
      )?_c('div',[(_vm.currentTime)?_c('div',{staticClass:"text-center"},[_c('h5',[_vm._v("Today Logged time")]),_c('h6',{staticClass:"mt-4 h1 text-success fw-bold"},[(_vm.hours)?_c('span',[_vm._v(_vm._s(_vm._f("formatTime")(_vm.hours))+":")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm._f("formatTime")(_vm.minutes))+":"+_vm._s(_vm._f("formatTime")(_vm.seconds)))])])]):_vm._e()]):(
        this.$store.state.getClockData.timein != '' &&
        this.$store.state.getClockData.timeout != ''
      )?_c('div',[(_vm.currentTime)?_c('div',{staticClass:"text-center"},[_c('h5',[_vm._v("Logged time")]),_c('h6',{staticClass:"mt-4 h1 text-success fw-bold"},[(_vm.hours)?_c('span',[_vm._v(_vm._s(_vm._f("formatTime")(_vm.hours))+":")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm._f("formatTime")(_vm.minutes))+":"+_vm._s(_vm._f("formatTime")(_vm.seconds)))])])]):_vm._e()]):_vm._e(),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"form-group d-flex justify-content-evenly px-3 mb-3 text-uppercase"}),_c('div',{staticClass:"form-group"}),(_vm.$store.state.getClockData == null)?_c('input',{staticClass:"btn btn-primary text-white fw-bold",attrs:{"type":"submit","value":"Time In"}}):(
          _vm.$store.state.getClockData.timein != '' &&
          _vm.$store.state.getClockData.timeout == null
        )?_c('input',{staticClass:"btn btn-primary text-white fw-bold mx-1",attrs:{"type":"submit","value":"Time Out"}}):_vm._e(),_c('a',{staticClass:"btn btn-secondary text-white fw-bold mx-1",on:{"click":_vm.backBtn}},[_vm._v("Back")])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',[_c('span',{attrs:{"id":"greetings"}},[_vm._v("Welcome!")]),_c('span',{attrs:{"id":"fullname"}})])}]

export { render, staticRenderFns }