<template>
  <div class="clock-wrapper">
    <div class="box text-center">
      <h2>
        <span id="greetings">Welcome!</span>
        <span id="fullname"></span>
      </h2>
      <vue-clock v-if="this.$store.state.getClockData == null" />
      <div
        v-else-if="
          this.$store.state.getClockData.timein != '' &&
          this.$store.state.getClockData.timeout == null
        "
      >
        <div class="text-center" v-if="currentTime">
          <h5>Today Logged time</h5>
          <h6 class="mt-4 h1 text-success fw-bold">
            <span v-if="hours">{{ hours | formatTime }}:</span
            ><span>{{ minutes | formatTime }}:{{ seconds | formatTime }}</span>
          </h6>
        </div>
      </div>
      <div
        v-else-if="
          this.$store.state.getClockData.timein != '' &&
          this.$store.state.getClockData.timeout != ''
        "
      >
        <div class="text-center" v-if="currentTime">
          <h5>Logged time</h5>
          <h6 class="mt-4 h1 text-success fw-bold">
            <span v-if="hours">{{ hours | formatTime }}:</span
            ><span>{{ minutes | formatTime }}:{{ seconds | formatTime }}</span>
          </h6>
        </div>
      </div>
      <b-form @submit.prevent="onSubmit">
        <div
          class="form-group d-flex justify-content-evenly px-3 mb-3 text-uppercase"
        >
          <!-- <b-form-radio
            id="timein"
            v-model="form.type"
            type="radio"
            value="timein"
            required
            >Time In</b-form-radio
          >
          <b-form-radio
            id="timeout"
            v-model="form.type"
            type="radio"
            value="timeout"
            required
            >Time Out</b-form-radio
          > -->
        </div>
        <div class="form-group"></div>
        <input
          v-if="$store.state.getClockData == null"
          type="submit"
          class="btn btn-primary text-white fw-bold"
          value="Time In"
        />
        <input
          v-else-if="
            $store.state.getClockData.timein != '' &&
            $store.state.getClockData.timeout == null
          "
          type="submit"
          class="btn btn-primary text-white fw-bold mx-1"
          value="Time Out"
        />

        <a @click="backBtn" class="btn btn-secondary text-white fw-bold mx-1"
          >Back</a
        >
      </b-form>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import VueClock from "@dangvanthanh/vue-clock";
import axios from "axios";
require("../axios");
export default {
  components: { VueClock },
  data() {
    return {
      form: {
        type: null,
        clockin_comment: null,
      },
      currentTime: null,
      speed: 1000,
    };
  },
  methods: {
    asset(path) {
      let origin = window.location.origin;
      return origin + "/" + path;
    },
    backBtn() {
      if (localStorage.getItem("role_id") == "7") {
        this.$router.push({ name: "personal-dashboard" });
      } else {
        this.$router.push({ name: "dashboard" });
      }
    },
    timeFormat(date) {
      return moment(date).format("h:mm:ss");
    },
    onSubmit() {
      console.log('formData', this.form);
      if (this.$store.state.getClockData == null) {
        this.form.type = "timein";
      } else {
        this.form.type = "timeout";
      }
      axios.post("attendance/add", this.form).then(({ data }) => {
        console.log('data', data);

        if (data.error) {
          alert("Please Clock Out from your last Attendance.");
          /* this.flashMessage.error({
            message: data.error,
            time: 3000,
          }); */
        } else {
          /* this.flashMessage.error({
                        message: 'Attendance updated!',
                        time: 3000,
                        }); */
          if (localStorage.getItem("role_id") == "7") {
            //this.$router.push({ name: "personal-dashboard" });
          } else {
            // this.$router.push({ name: "dashboard" });
          }
          this.$store.dispatch("loadClockGetData");
          if (this.form.type == "timein") {
            setTimeout(this.countdown, 1000);
          }
        }
      });
    },
    countdown() {
      if (
        this.$store.state.getClockData.timein != "" &&
        (this.$store.state.getClockData.timeout == null ||
          this.$store.state.getClockData.timeout == null)
      ) {
        this.currentTime = Date.parse(new Date()) - Date.parse(this.timeIn);
        if (this.currentTime > 0) {
          setTimeout(this.countdown, this.speed);
        } else {
          this.currentTime = null;
        }
      } else {
        this.currentTime = Date.parse(this.timeOut) - Date.parse(this.timeIn);
      }
    },
  },
  computed: {
    cc() {
      return this.$store.state.clockData.cc;
    },
    tz() {
      return this.$store.state.clockData.tz;
    },
    tf() {
      return this.$store.state.clockData.tf;
    },
    rfid() {
      return this.$store.state.clockData.rfid;
    },
    seconds() {
      return Math.floor((this.currentTime / 1000) % 60);
    },
    minutes() {
      return Math.floor((this.currentTime / 1000 / 60) % 60);
    },
    hours() {
      return Math.floor((this.currentTime / (1000 * 60 * 60)) % 24);
    },
    timeIn() {
      return this.$store.state.getClockData.timein;
    },
    timeOut() {
      return this.$store.state.getClockData.timeout;
    },
    getClockData() {
      return this.$store.state.getClockData;
    },
  },
  created() {
    this.$store.dispatch("switchLoader", true);
    this.$store.dispatch("loadClockData");
    this.$store.dispatch("loadClockGetData");
  },
  filters: {
    formatTime(value) {
      if (value < 10) {
        return "0" + value;
      }
      return value;
    },
  },
  mounted() {
    this.currentTime = Date.parse(new Date()) - Date.parse(this.timein);
    setTimeout(this.countdown, 2000);
  },
};
</script>
